import { CsvProvider } from "../../context";

import { AdminPanel } from "../../components/admin-panel";

export const AdminDashboard = () => {
  return (
    <>
      <CsvProvider>
        <AdminPanel />
      </CsvProvider>
    </>
  );
};
