import { useState } from "react";
import globalData from "../../../dictionary/global.json";
import ReactPlayer from "react-player";
import styles from "./workflow.module.css";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";
import { SectionHeader } from "../../ui";
import { REACT_APP_YOUTUBE_VIDEO_LINK } from "../../../lib/utils/url";

export const Workflow = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <motion.section
      className="px-4 py-16 md:py-24 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-12 text-center">
        <motion.div variants={item}>
          <SectionHeader
            title={globalData?.HOME_PAGE?.WORKFLOW?.HEADING}
            description={globalData?.HOME_PAGE?.WORKFLOW?.DESCRIPTION}
          />
        </motion.div>
        <motion.div
          className={`h-auto w-full  ${styles.video_player}`}
          variants={item}
        >
          {!isVideoLoaded && (
            <div className={`${styles.skeleton_shimmer}`}></div>
          )}
          <ReactPlayer
            url={REACT_APP_YOUTUBE_VIDEO_LINK}
            className={styles.responsive_player}
            controls={true}
            onReady={() => setIsVideoLoaded(true)}
            style={{ display: isVideoLoaded ? "block" : "none" }}
          />
        </motion.div>
      </div>
    </motion.section>
  );
};
