import React from "react";

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  orText?: string;
}

export const Divider: React.FC<DividerProps> = ({
  className = "",
  orText = "OR",
  ...props
}) => {
  const renderOrText = orText ? (
    <span className="text-xs uppercase text-fg-solid">{orText}</span>
  ) : null;

  const dividerLineStyle = "my-3 flex h-[0.0625rem] w-full bg-fg-line";
  return (
    <div className={`flex items-center gap-3 ${className}`} {...props}>
      <div className={dividerLineStyle}></div>
      {renderOrText}
      <div className={dividerLineStyle}></div>
    </div>
  );
};
