import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import globalData from "../../../dictionary/global.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { logout } from "../../../redux/slice/authSlice";
import axios from "axios";
import { BASE_URL } from "../../../lib/utils/url";
import { PiListBold, PiXBold } from "react-icons/pi";
import { BlackLogo, IconButton, WhiteLogo } from "../../ui";

const linkBaseStyle =
  "flex h-9 w-fit items-center justify-center rounded-md px-3 text-center text-input font-medium max-md:w-full max-md:justify-start";
const hoverStyle = "hover:bg-bg-bg-hover md:hover:bg-overlay-line";

const NavItem = ({
  to,
  label,
  onClick,
}: {
  to: string;
  label: string;
  onClick?: () => void;
}) => (
  <li>
    <NavLink
      to={to}
      className={`${linkBaseStyle} ${hoverStyle}`}
      onClick={onClick}
    >
      {label}
    </NavLink>
  </li>
);

export const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navItems = [
    { to: "/home", label: globalData?.NAVBAR?.[0] },
    { to: "/about-us", label: globalData?.NAVBAR?.[1] },
    { to: "/services", label: globalData?.NAVBAR?.[2] },
    { to: "/contact", label: globalData?.NAVBAR?.[3] },
  ];
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const refreshToken = useSelector(
    (state: RootState) => state.auth.tokens?.refresh?.token,
  );
  const dispatch = useDispatch();

  const handleLogout = async () => {
    console.log(BASE_URL, "Base");
    try {
      if (refreshToken) {
        await axios.post(`${BASE_URL}/v1/auth/logout`, { refreshToken });
        dispatch(logout());
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth >= 768 && isMenuOpen) {
      setMenuOpen(false);
    }
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, [isMenuOpen]);

  return (
    <header
      className={`absolute z-50 h-24 w-full ${isMenuOpen ? "bg-bg-default" : "bg-transparent"}`}
    >
      <div className="mx-auto flex h-full max-w-7xl items-center px-6 lg:p-0">
        <div className="flex-1">
          {isMenuOpen ? (
            <BlackLogo
              className={`max-md:w-28 ${isMenuOpen ? "text-bg-default" : ""}`}
            />
          ) : (
            <WhiteLogo
              className={`max-md:w-28 ${isMenuOpen ? "text-bg-default" : ""}`}
            />
          )}
        </div>
        <div className="flex flex-1 justify-end md:hidden">
          <IconButton
            variant="gray"
            size="medium"
            type="ghost"
            onClick={handleMenuClick}
            className="hover:!bg-fg-solid"
            icon={
              isMenuOpen ? (
                <PiXBold className="text-xl text-primary-text-contrast" />
              ) : (
                <PiListBold className="text-xl text-bg-default" />
              )
            }
          />
        </div>
        <>
          <ul
            className={`gap-3 text-bg-base md:flex ${isMenuOpen ? "fixed left-0 top-20 flex h-full w-full flex-col bg-bg-default px-4 text-primary-text-contrast" : "hidden"}`}
          >
            {navItems.map((item, index) => (
              <NavItem key={index} to={item.to} label={item.label} onClick={closeMenu} />
            ))}
            <div className="flex flex-col justify-end gap-3 text-bg-base md:hidden">
              {!isAuthenticated ? (
                <>
                  <Link
                    to="/auth/login"
                    className={`text-primary-text-contrast ${linkBaseStyle} ${hoverStyle}`}
                  >
                    Log In
                  </Link>
                  <Link
                    to="/auth/signup"
                    className={`${linkBaseStyle} border border-fg-solid bg-fg-text hover:!bg-fg-solid-hover ${hoverStyle}`}
                  >
                    Sign Up
                  </Link>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={`text-primary-text-contrast ${linkBaseStyle} ${hoverStyle}`}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                  <button
                    type="button"
                    className={`border border-fg-solid bg-fg-text hover:!bg-fg-solid-hover ${linkBaseStyle} ${hoverStyle}`}
                    onClick={() =>
                      navigate("/calculate?type=courtFee", {
                        replace: true,
                      })
                    }
                  >
                    Calculate
                  </button>
                  <button
                    type="button"
                    className={`border border-fg-solid bg-fg-text hover:!bg-fg-solid-hover ${linkBaseStyle} ${hoverStyle}`}
                    onClick={() =>
                      navigate("/admin", {
                        replace: true,
                      })
                    }
                  >
                    Admin
                  </button>
                </>
              )}
            </div>
          </ul>
        </>
        <div className="hidden flex-1 justify-end gap-3 text-bg-base md:flex">
          {!isAuthenticated ? (
            <>
              <Link
                to="/auth/login"
                className={`${linkBaseStyle} ${hoverStyle}`}
              >
                Log In
              </Link>
              <Link
                to="/auth/signup"
                className={`${linkBaseStyle} border border-fg-solid bg-fg-text ${hoverStyle}`}
              >
                Sign Up
              </Link>
            </>
          ) : (
            <>
              <button
                type="button"
                className={`${linkBaseStyle} ${hoverStyle}`}
                onClick={handleLogout}
              >
                Logout
              </button>
              <button
                type="button"
                className={`border border-fg-solid bg-fg-text hover:!bg-fg-solid-hover ${linkBaseStyle} ${hoverStyle}`}
                onClick={() =>
                  navigate("/calculate?type=courtFee", { replace: true })
                }
              >
                Calculate
              </button>
              <button
                type="button"
                className={`border border-fg-solid bg-fg-text hover:!bg-fg-solid-hover ${linkBaseStyle} ${hoverStyle}`}
                onClick={() =>
                  navigate("/admin", {
                    replace: true,
                  })
                }
              >
                Admin
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
