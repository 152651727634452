import { Link } from "react-router-dom";

type LinkItemProps = {
  linkData: {
    HREF: string;
    NAME: string;
  };
};

export const LinkItem: React.FC<LinkItemProps> = ({ linkData }) => (
  <li>
    <Link
      to={linkData.HREF}
      className="text-[12px] md:text-[14px] flex h-8 w-fit items-center justify-center rounded-md px-2.5 text-center text-input hover:bg-overlay-line"
    >
      {linkData.NAME}
    </Link>
  </li>
);
