import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormInput,
  BlackLogoWithIcon,
} from "../../../components/ui";
import globalData from "../../../dictionary/global.json";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from 'react-redux';
import { User, signup } from '../../../redux/slice/authSlice'
import { AppDispatch, store } from '../../../redux/store';
import { Link, useNavigate } from "react-router-dom";
import { googleAuth } from "../../../lib/utils/google-auth";

export const SignUp: React.FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const[username,setUsername]=useState('')
  const[email,setEmail]=useState('')
  const[password,setPassword]=useState('')
  const navigate = useNavigate();

  const togglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  }; 
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData:User={
      name:username,
      email:email,
      password:password
    }
    if(username.length>0 && email.length>0 && password.length>0 ){
      dispatch(signup(userData));
    }
  };

  useEffect(() => {
    const navigateToHomepage = () => {
      navigate('/'); 
    };
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.auth.isAuthenticated) {
        navigateToHomepage();
      }
      else{
        console.log("not authenticated")
      }
    });
    return unsubscribe; 
  }, [navigate, store]);

  return (
    <section>
      <div className="flex h-screen p-5">
        <div className="w-full">
          <div className="mx-auto flex h-full w-3/4 flex-col items-center pt-32 gap-12">
            <Link to="/" className="cursor-pointer">
              <BlackLogoWithIcon />
            </Link>
            <main className="flex w-[25rem] flex-col gap-6">
              <div className="flex flex-col gap-2 text-center">
                <h1 className="text-2xl md:text-3xl font-semibold text-fg-text-contrast">
                  {globalData?.AUTH_SCREENS?.SIGN_UP?.WELCOME_TEXT}
                </h1>
                <h2 className="md:text-lg text-fg-text">
                  {globalData?.AUTH_SCREENS?.SIGN_UP?.GETTING_STARTED_TEXT}
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4">
                    <FormInput
                      label={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.NAME_INPUT_LABEL
                      }
                      placeholder={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.NAME_INPUT_PLACEHOLDER
                      }
                      onChange={handleNameChange}
                    />
                    <FormInput
                      label={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.EMAIL_INPUT_LABEL
                      }
                      placeholder={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.EMAIL_INPUT_PLACEHOLDER
                      }
                      onChange={handleEmailChange}
                    />
                    <FormInput
                      label={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.PASSWORD_INPUT_LABEL
                      }
                      placeholder={
                        globalData?.AUTH_SCREENS?.SIGN_UP?.FORM
                          ?.PASSWORD_INPUT_PLACEHOLDER
                      }
                      onChange={handlePasswordChange}
                      type={isPasswordVisible ? "text" : "password"}
                      isPassword
                      isPasswordVisible={isPasswordVisible}
                      togglePasswordVisibility={togglePasswordVisibility}
                    />
                    <p className="text-sm font-medium text-fg-text-contrast">
                      {globalData?.AUTH_SCREENS?.SIGN_UP?.CONFIRMATION_TEXT}
                    </p>
                  </div>
                  <div className="flex flex-col gap-4 text-center">
                    <Button variant="primary" type="solid" size="medium" isSubmit={true}>
                      {
                        globalData?.AUTH_SCREENS?.SIGN_UP?.BUTTONS_TEXT
                          ?.SIGNUP_BUTTON
                      }
                    </Button>
                    <Divider />
                    <Button variant="gray" type="solid" size="medium" onClick={googleAuth}>
                      <FcGoogle className="text-xl" />
                      <p className="-ml-4 w-full">
                        {
                          globalData?.AUTH_SCREENS?.SIGN_UP?.BUTTONS_TEXT
                            ?.GOOGLE_PROVIDER
                        }
                      </p>
                    </Button>
                    <p className="text-sm text-fg-text-contrast">
                      <span>
                        {
                          globalData?.AUTH_SCREENS?.SIGN_UP
                            ?.ACCOUNT_EXISTS_TEXT?.[0]
                        }
                      </span>
                      <Link
                        to="/auth/login"
                        className="cursor-pointer text-primary-solid underline hover:text-primary-border-hover"
                      >
                        {
                          globalData?.AUTH_SCREENS?.SIGN_UP
                            ?.ACCOUNT_EXISTS_TEXT?.[1]
                        }
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </main>
          </div>
        </div>
        <img alt="auth" src="/assets/images/auth-image.png" className="hidden md:flex w-4/5" />
      </div>
    </section>
  );
};
