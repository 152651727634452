import { Badge, SectionHeader, TeamCard } from "../../ui";
import globalData from "../../../dictionary/global.json";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";

export const OurTeam = () => {
  return (
    <motion.section
      className="px-4 py-16 md:py-24 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <Badge text="Our Team" className="mb-6 justify-center" />
        <motion.div
          className="flex flex-col items-center gap-12"
          variants={item}
        >
          <SectionHeader
            title={globalData?.ABOUT_US_PAGE?.TEAM.TITLE}
            description={globalData?.ABOUT_US_PAGE?.TEAM?.DESCRIPTION}
            className="text-center"
          />
          <motion.div
            className="flex w-full flex-row flex-wrap justify-center xl:justify-between gap-9"
            variants={item}
          >
            {globalData?.ABOUT_US_PAGE?.TEAM?.TEAM_MEMBERS.map(
              (member, index) => (
                <TeamCard
                  key={index}
                  imgSrc={member.IMG_SRC}
                  name={member.NAME}
                  contact={member.CONTACT}
                />
              ),
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};
