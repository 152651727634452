import { CSVLink } from 'react-csv';
import { useCsvContext } from '../../../context';

export const CsvEditor = ({ selectedType, provinceId }: { selectedType: ('court-fee-csv' | 'suit-valuation-csv' | ''), provinceId: string | null }) => {
  const {
    editedData,
    message,
    newColumnName,
    setNewColumnName,
    handleEditChange,
    handleUploadCSVToSever,
    addRow,
    addColumn,
    deleteRow,
    deleteColumn,
    setCsvData,
    isUploading,
  } = useCsvContext();

  if (editedData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation']?.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(editedData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation'][0]).map((key) => ({
    label: key,
    key,
  }));

  return (
    <div className='min-h-full'>

      <div className="p-4 w-full">
        <div className="flex space-x-4 my-2 justify-end">
          <button
            onClick={() => handleUploadCSVToSever(selectedType, provinceId)}
            className="bg-primary-solid hover:bg-info-text-contrast text-bg-base font-bold py-2 px-4 rounded"
          >
            {isUploading ? 'Uploading...' : 'Upload File'}
          </button>
          <button
            onClick={() => setCsvData({ courtFee: [], suitValuation: [] })}
            className="bg-alert-solid hover:bg-alert-text text-bg-base font-bold py-2 px-4 rounded"
          >
            Remove File
          </button>

          <CSVLink
            data={editedData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation']}
            headers={headers}
            filename="edited_data.csv"
            className="bg-success-solid hover:bg-success-solid-hover text-bg-base font-bold py-2 px-4 rounded"
          >
            Download CSV
          </CSVLink>
          <button
            onClick={() => addRow(selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation')}
            className="bg-info-text hover:bg-info-text-contrast text-bg-base font-bold py-2 px-4 rounded"
          >
            Add Row
          </button>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={newColumnName}
              onChange={(e) => setNewColumnName(e.target.value)}
              placeholder="Column Name"
              className="border p-2 rounded"
            />
            <button
              onClick={addColumn}
              className="bg-info-text  hover:bg-info-text-contrast text-bg-base font-bold py-2 px-4 rounded"
            >
              Add Column
            </button>
          </div>
        </div>
        {message && <p className="text-warning-solid">{message}</p>}

        <table className=" mb-4 table-auto">
          <thead>
            <tr className="bg-secondary-border-hover">
              {Object.keys(editedData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation'][0]).map((key) => (
                <th key={key} className="border p-2 relative">

                  {key}
                  <button
                    onClick={() => deleteColumn(key)}
                    className="absolute  right-0  hover:bg-alert-text  top-2 bg-alert-solid py-0 text-bg-base p-1 rounded-sm"
                  >                  X

                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {editedData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation'].map((row, rowIndex) => (
              <tr key={rowIndex}>

                {Object.keys(row).map((key) => (
                  <td key={key} className="border p-2">
                    <input
                      type="text"
                      value={row[key]}
                      onChange={(e) => handleEditChange(selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation', rowIndex, key, e.target.value)}
                      className="bg-bg-default border w-[150px] rounded px-3 py-1"
                    />
                  </td>
                ))}
                <td>
                  <button
                    onClick={() => deleteRow(selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation', rowIndex)}
                    className="bg-alert-solid hover:bg-alert-text text-bg-base font-bold py-1 px-2 rounded"
                  >
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};
