import { Skeleton } from "..";

interface BadgeProps {
  text: string;
  loading?: boolean;
  className?: string;
}

export const Badge = ({ className, text, loading }: BadgeProps) => {
  return (
    <>
      {loading ? (
        <p>
          <Skeleton className="h-4 w-28" />
        </p>
      ) : (
        <p
          className={`flex w-full text-lg font-bold text-[#FF3F02] ${className}`}
        >
          {text}
        </p>
      )}
    </>
  );
};
