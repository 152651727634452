import { useEffect, useState } from "react";
import { Button } from "../../ui";
import { SelectCountryDialog } from "../select-country-dialog";
import { SelectProvinceDialog } from "../select-province-dialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchCountries } from "../../../redux/slice/dashboardSlice";

interface HeaderProps {
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setProvince: React.Dispatch<React.SetStateAction<string>>;
  setProvinceId: React.Dispatch<React.SetStateAction<string>>;
}

export const Header: React.FC<HeaderProps> = ({
  setCountry,
  setProvince,
  setProvinceId
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { countries, provinces, loading, error } = useSelector((state: RootState) => state.dashboard);
  const [isCountryDialogOpen, setIsCountryDialogOpen] = useState(false);
  const [isProvinceDialogOpen, setIsProvinceDialogOpen] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const handleCountrySelected = (country: string) => {
    setSelectedCountry(country);
    setCountry(country);
    setProvince("");
    setProvinceId("");
    setSelectedProvince("")
    setIsCountryDialogOpen(false);
  };

  const updateProvinceId = (provinceName: string) => {
    console.log('Update province id', provinceName);
    const selectedProvinceObj = provinces.find((province) => province.name === provinceName);
    console.log('Selected province obj', selectedProvinceObj);
    if (selectedProvinceObj?.id) {
      setProvinceId(selectedProvinceObj.id);
    }
  }
  const handleProvinceSelected = (province: string) => {
    setSelectedProvince(province);
    setProvince(province);
    for (const country of countries) {
      if (country.provinces) {
        for (const listed_province of country.provinces) {
          if (listed_province.name.toLowerCase() === province.toLowerCase()) {
            setProvinceId(listed_province.id as string);
          }
        }
      }
    }
    updateProvinceId(province);
    setIsProvinceDialogOpen(false);
  };
  
  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  useEffect(() => {
    updateProvinceId(selectedProvince);
  }, [provinces]);

  return (
    <>
      <div className="ml-60 p-4">
        <div className="flex justify-end gap-4">
          <div className="flex flex-col gap-2">
            <Button
              variant="primary"
              size="medium"
              type="solid"
              onClick={() => setIsCountryDialogOpen(true)}
            >
              Select country
            </Button>
            {selectedCountry ? (
              <span className="text-sm italic text-fg-text">
                {selectedCountry}
              </span>
            ) : (
              <span className="text-sm italic text-fg-text">
                No country selected
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Button
              variant="primary"
              size="medium"
              type="solid"
              onClick={() => setIsProvinceDialogOpen(true)}
              disabled={!selectedCountry}
            >
              Select province/state
            </Button>
            {selectedProvince ? (
              <span className="text-sm italic text-fg-text">
                {selectedProvince}
              </span>
            ) : (
              <span className="text-sm italic text-fg-text">
                No province/state selected
              </span>
            )}
          </div>
        </div>
      </div>
      {isCountryDialogOpen && (
        <SelectCountryDialog
          isDialogOpen={isCountryDialogOpen}
          setIsDialogOpen={setIsCountryDialogOpen}
          onSave={handleCountrySelected}
        />
      )}
      {isProvinceDialogOpen && (
        <SelectProvinceDialog
          selectedCountry={selectedCountry}
          isDialogOpen={isProvinceDialogOpen}
          setIsDialogOpen={setIsProvinceDialogOpen}
          onSave={handleProvinceSelected}
        />
      )}
    </>
  );
};
