import { ContactBanner, HeadBanner } from "../../components/ui";
import { OurServices, Testimonials } from "../../components/sections";
import globalData from "../../dictionary/global.json";

export const Services = () => {
  return (
    <>
      <section className="h-screen">
        <HeadBanner
          title={globalData?.SERVICES_PAGE?.TITLE}
          imgUrl={globalData?.SERVICES_PAGE.IMAGE_URL}
        />
      </section>
      <OurServices />
      <Testimonials />
      <ContactBanner className="pb-16 md:pb-24 pt-12" />
    </>
  );
};
