import { useState, FC } from "react";
import { PiMagnifyingGlassDuotone } from "react-icons/pi";
import { PiXCircleFill } from "react-icons/pi";

interface SearchBarProps {
  onSearch: (query: string) => void;
  className?: string;
}

export const SearchBar: FC<SearchBarProps> = ({ onSearch, className }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleClearSearch = () => {
    setSearchQuery("");
    onSearch("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <div
      className={`flex h-11 w-full items-center rounded-md border border-fg-line bg-transparent px-3 text-sm hover:border-fg-border ${className}`}
    >
      <PiMagnifyingGlassDuotone
        className="text-xl text-fg-text"
        onClick={handleClearSearch}
      />
      <input
        type="text"
        className="h-full w-full bg-transparent px-1 text-fg-text-contrast outline-none placeholder:text-fg-solid focus:border-fg-border disabled:cursor-not-allowed disabled:opacity-50"
        value={searchQuery}
        onChange={handleChange}
        placeholder="Search record here"
      />
      {searchQuery && (
        <PiXCircleFill
          className="text-xl text-fg-text"
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
};
