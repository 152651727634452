import { Fragment, FC } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoChevronDownOutline, IoChevronForwardOutline } from "react-icons/io5";

const classNames = (...classes: (string | boolean)[]): string => {
  return classes.filter((c) => typeof c === "string" && c !== "").join(" ");
};

interface DropdownProps {
  className?: string;
  options: { label: string; subOptions?: string[] }[];
  selectedValue: string;
  onChange: (value: string) => void;
  placeholder?: string;
  menuWidth?: string;
  disabled?: boolean;
}
export const Dropdown: FC<DropdownProps> = ({
  className,
  options,
  selectedValue,
  onChange,
  placeholder,
  menuWidth,
  disabled,
}) => {
  const isPlaceholder = selectedValue === placeholder;

  return (
    <Menu
      as="div"
      className={`relative inline-block w-full text-left ${className}`}
    >
      <div>
        <Menu.Button
          disabled={disabled}
          className={`flex h-9 w-full items-center justify-between gap-x-1.5 rounded-md border border-secondary-solid-hover bg-transparent px-3.5 py-2 shadow-sm focus:border-fg-border md:h-11 ${
            isPlaceholder
              ? "text-gray-400"
              : "font-medium text-fg-text-contrast"
          }`}
        >
          {selectedValue}
          <IoChevronDownOutline className="-mr-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md border border-secondary-solid-hover bg-bg-default shadow-lg focus:outline-none ${menuWidth}`}
        >
          <div className="max-h-60 overflow-y-auto px-1.5 md:py-1 ">
            {options.map((option, index) => (
              <Menu.Item key={option.label}>
                {({ active }) => (
                  <div>
                    {option.subOptions && index === 0 ? (
                      <Menu as="div" className="flex w-full text-left">
                        <Menu.Button
                          className={classNames(
                            active ? "rounded-md bg-primary-bg-hover" : "",
                            "flex w-full items-center justify-between p-2 text-left text-sm",
                          )}
                        >
                          {option.label}
                          <IoChevronForwardOutline className="ml-2 h-5 w-5 text-fg-text" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute left-full top-0 ml-2 mt-0 w-56 rounded-md bg-white p-1 shadow-lg focus:outline-none ${menuWidth}`}
                          >
                            {option.subOptions.map((subOption) => (
                              <Menu.Item key={subOption}>
                                {({ active: activeSub }) => (
                                  <button
                                    type="button"
                                    className={classNames(
                                      activeSub ? "bg-primary-bg-hover" : "",
                                      "flex w-full rounded-md p-2 text-left text-sm",
                                    )}
                                    onClick={() => onChange(subOption)}
                                  >
                                    {subOption}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      // If it's not the first option or has no subOptions, render a normal button
                      <button
                        type="button"
                        className={classNames(
                          active ? "bg-primary-bg-hover" : "",
                          "flex w-full rounded-md p-2 text-left text-sm",
                        )}
                        onClick={() => onChange(option.label)}
                      >
                        {option.label}
                      </button>
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
