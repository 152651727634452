import globalData from "../../../dictionary/global.json";
import { Badge, BenefitCard, SectionHeader } from "../../ui";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";

export const OurBenefits = () => {
  return (
    <motion.section
      className="px-4 py-16 md:py-24 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <Badge
          text={globalData?.HOME_PAGE?.OUR_BENEFITS?.BANNER_TEXT}
          className="mb-6 justify-center"
        />
        <div className="flex flex-col gap-12">
          <SectionHeader
            title={globalData?.HOME_PAGE?.OUR_BENEFITS?.HEADING}
            description={globalData?.HOME_PAGE?.OUR_BENEFITS?.DESCRIPTION}
            className="items-center text-center"
          />
          <div className="flex flex-col flex-wrap justify-center gap-8 max-md:items-center md:flex-row xl:justify-between">
            {globalData?.HOME_PAGE?.OUR_BENEFITS?.BENEFITS?.map(
              (benefit, index) => (
                <motion.div key={index} variants={item}>
                  <BenefitCard
                    type="home"
                    key={index}
                    title={benefit.TITLE}
                    iconSrc={benefit.IMAGE_URL}
                    description={benefit.DESCRIPTION}
                  />
                </motion.div>
              ),
            )}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};
