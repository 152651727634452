import { useState } from "react";
import { getFormattedDate } from "../../../lib/utils/get-formatted-date";
import { Button, SearchTable } from "../../ui";
import { SearchBar } from "./search-bar";
import { GrSort } from "react-icons/gr";
import { FaChevronLeft } from "react-icons/fa6";
import globalData from "../../../dictionary/global.json";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

export const SearchHistory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryD] = useDebounce(searchQuery, 300);

  const navigate = useNavigate();

  return (
    <div className="mx-auto flex flex-col py-16 lg:max-w-7xl">
      <div className="mb-12 flex flex-col gap-[72px] md:flex-row md:items-center md:justify-between">
        <button className="flex flex-row items-center" onClick={() => navigate(-1)}>
          <FaChevronLeft className="text-2xl text-fg-text-contrast" />
          <div className="text-nowrap text-lg font-bold md:text-3xl">
            {globalData?.SEARCH_HISTORY_PAGE?.TITLE}
          </div>
        </button>
        <Button variant="primary" type="solid" size="medium">
          {globalData?.SEARCH_HISTORY_PAGE?.BUY_SEARCHES}
        </Button>
      </div>
      <div className="flex flex-col gap-4 pl-8">
        <div className="flex flex-col justify-between gap-6 md:flex-row md:items-center md:gap-0 ">
          <p className="text-lg font-bold">
            {globalData?.SEARCH_HISTORY_PAGE?.CHECK_PREVIOUS_SEARCHED}
          </p>
          <SearchBar
            onSearch={(query) => setSearchQuery(query)}
            className="!max-w-96"
          />
        </div>
        {/* <div className="space-between flex items-center gap-2">
          <GrSort className="text-2xl text-primary-solid" />
          <div className="text-xs font-bold text-primary-solid md:text-xl">
            By Date
          </div>
        </div> */}
        <div className="flex flex-col gap-0">
          <div className="border-x border-t border-fg-text-contrast px-12 py-4 font-semibold text-fg-text md:text-lg">
            {getFormattedDate()}
          </div>
          <SearchTable searchQuery={searchQueryD} />
        </div>
      </div>
    </div>
  );
};
