import { useEffect, useState } from "react";
import { Sidebar } from "./sidebar";
import { WelcomeScreen } from "./welcome-screen";
import { Header } from "./header";
import { useSearchParams, Navigate } from "react-router-dom";
import { CSVManager } from "../../pages";
import { CourtFeeTable } from "./court-fee-table";
import { SuitValuationTable } from "./suit-valuation-table";

export const AdminPanel = () => {
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [provinceId, setProvinceId] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const activeComponent = searchParams.get("view") || "welcome";

  useEffect(() => {
    if (country && province) {
      setActiveComponent("courtFee");
    }
  }, [country, province]);

  const setActiveComponent = (component: any) => {
    setSearchParams({ view: component });
  };

  const renderComponent = () => {
    if (!country || !province) {
      if (activeComponent !== "welcome") {
        return <Navigate to="/admin" replace />;
      }
    }

    console.log(activeComponent);

    switch (activeComponent) {
      case "courtFee":
        return <CourtFeeTable provinceId={provinceId}/>;
      case "suitValuation":
        return <SuitValuationTable provinceId={provinceId} />;
      case "courtFeeCSV":
        return <CSVManager provinceId={provinceId} selectedType="court-fee-csv" />;
      case "suitValuationCSV":
        return (
          <CSVManager provinceId={provinceId} selectedType="suit-valuation-csv" />
        );
      default:
        console.log("Rendering welcome screen");
        return <WelcomeScreen />;
    }
  };

  return (
    <>
      <Header setCountry={setCountry} setProvince={setProvince}  setProvinceId={setProvinceId} />
      <Sidebar
        isEnabled={country && province}
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />
      <div className="px-4 py-32 ml-60 bg-bg-base">{renderComponent()}</div>
    </>
  );
};
