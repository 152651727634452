import React from "react";
import { IconButton, Skeleton } from "..";

interface BenefitCardProps {
  iconSrc: string;
  title: string;
  description: string;
  className?: string;
  loading?: boolean;
  type?: "home" | "about";
}

export const BenefitCard: React.FC<BenefitCardProps> = ({
  iconSrc,
  title,
  description,
  className,
  loading,
  type,
}) => {
  return (
    <div
      className={`flex ${type === "home" ? "md:w-[394px] w-80 flex-col py-5 px-4 md:p-6 shadow-[0_4px_100px_20px_rgba(175,173,181,0.3)]" : "lg:w-[594px] flex-row "} gap-3 md:gap-4 rounded-xl ${className}`}
    >
      {loading ? (
        <>
          <Skeleton className="h-12 w-12 !rounded-full" />
          <div className="flex w-full flex-col gap-4">
            <Skeleton className="h-5 w-[250px]" />
            <Skeleton className="h-4 w-[100%]" />
            <Skeleton className="h-4 w-[80%]" />
          </div>
        </>
      ) : (
        <>
          <div
            className="flex aspect-square items-center justify-center border-none outline-none focus:outline-none h-12 w-12 rounded-full bg-[#F9F9F9]"
          >
            <img src={iconSrc} alt='icon'/>
          </div>
          <div className="flex w-full flex-col gap-3 md:gap-4">
            <h3 className="text-xl md:text-2xl font-bold text-fg-text-contrast">
              {title}
            </h3>
            <p className="text-sm md:text-lg font-medium leading-6 md:!leading-8 text-fg-text">
              {description}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
