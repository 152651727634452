import { useSearchParams } from "react-router-dom";
import {
  CalculatorAdvantage,
  Hero,
  OurBenefits,
  Workflow,
} from "../../components/sections";
import { ContactBanner } from "../../components/ui";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { loginWithProvider, refreshTokens } from "../../redux/slice/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isTokenExpired } from "../../lib/utils/token";

export const Home = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { isAuthenticated, tokens } = useSelector((state: RootState) => state.auth);
  const pid = searchParams.get("pid");

  useEffect(() => {
    if (pid) {
      dispatch(loginWithProvider(pid));
    }
  }, []);

  useEffect(() => {
    if (tokens?.access && isTokenExpired(tokens.access.expires)) {
      dispatch(refreshTokens(tokens?.refresh));
    }
  }, [tokens, isAuthenticated]);

  return (
    <>
      <Hero />
      <>
        <Workflow />
        <OurBenefits />
        <CalculatorAdvantage />
        <ContactBanner className="pb-16 pt-12 md:pb-24" />
      </>
    </>
  );
};
