import { FC, ReactNode } from "react";

interface ContactCtaProps {
  icon: ReactNode;
  title: string;
  description: string;
}

export const ContactCta: FC<ContactCtaProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="flex items-center gap-3">
      <div className="w-7">{icon}</div>
      <div className="flex flex-col">
        <h6 className="text-[13px] font-semibold">{title}</h6>
        <div className="text-[13px]">{description}</div>
      </div>
    </div>
  );
};
