import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../lib/utils/url';
import { Country, Province } from '../../types';



interface dashbaordState {
  countries: Country[];
  selectedCountry: Country | null;
  provinces: Province[];
  loading: boolean;
  error: string | null;
}

const initialState: dashbaordState = {
  countries: [],
  selectedCountry: null,
  provinces: [], // we may use provinces inside country
  loading: false,
  error: null,
};

export const fetchCountries = createAsyncThunk<Country[], void, { rejectValue: string }>('admin/fetchCountries', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/v1/country/get-all-countries`);
    localStorage.setItem('countries', JSON.stringify(response.data))
    return response.data.countries;
  } catch (err: any) {
    return rejectWithValue(err.message as string);
  }
  // try {
  //   localStorage.setItem('countries', JSON.stringify(dummyCountries)); //hard coded countries
  //   return dummyCountries as Country[];
  // } catch (err:any) {
  //   return rejectWithValue(err);
  // }
});

export const addCountry = createAsyncThunk('admin/addCountry', async (country: string) => {
  const response = await axios.post(`${BASE_URL}/v1/country/create`, { name: country });
  return response.data.country;
});

export const addProvince = createAsyncThunk('admin/addProvince', async ({ newProvince, optedCountry }: { newProvince: string, optedCountry: string }) => {
  const response = await axios.post(`${BASE_URL}/v1/province/create`, { name: newProvince, optedCountry: optedCountry });
  return response.data.province;
});

export const fetchProvinces = createAsyncThunk('admin/fetchProvinces', async (countryId: string) => {
  const response = await axios.get(`${BASE_URL}/v1/province/country-id/${countryId}`);
  return response.data.provinces;
});

// export const addCity = createAsyncThunk('admin/addCity', async (city: City) => {
//   const response = await axios.post(`${BASE_URL}/provinces`, city);
//   return response.data;
// });

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectCountry: (state, action) => {
      state.selectedCountry = action.payload;
      state.provinces = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload;
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(addCountry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addCountry.fulfilled, (state, action) => {
      state.loading = false;
      state.countries.push(action.payload);
    });
    builder.addCase(addCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(fetchProvinces.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.loading = false;
      state.provinces = action.payload;
    });
    builder.addCase(fetchProvinces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
    builder.addCase(addProvince.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addProvince.fulfilled, (state, action) => {
      state.loading = false;
      state.provinces.push(action.payload);
    });
    builder.addCase(addProvince.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  },
});

export const { selectCountry } = dashboardSlice.actions;

export default dashboardSlice.reducer;

