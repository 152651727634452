"use client";

import React, { forwardRef, useRef } from "react";
import cn from "classnames";
import { Loading } from "../loading";
import styles from "./button.module.css";
import { mergeRefs } from "react-merge-refs";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: keyof JSX.IntrinsicElements | React.ElementType;
  className?: string;
  variant?: "primary" | "gray";
  size?: "small" | "medium" | "large";
  type?: "solid" | "soft" | "surface" | "outline" | "ghost";
  form?: string;
  leading?: boolean;
  trailing?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isSubmit?: boolean;

}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as: Component = "button",
      className,
      children,
      variant = "primary",
      size = "small",
      type = "solid",
      leadingIcon,
      trailingIcon,
      leading = false,
      trailing = false,
      loading = false,
      disabled = false,
      onClick,
      isSubmit = false,
      ...rest
    },
    buttonRef,
  ) => {
    const ref = useRef(null);
    const rootClassName = cn(
      styles.root,
      variant && styles[variant],
      size && styles[size],
      type && styles[type],
      {
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      },
      className,
    );

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
      e.preventDefault();
    };

    return React.createElement(
      Component,
      {
        ref: mergeRefs([ref, buttonRef]),
        className: rootClassName,
        "data-size": size,
        "data-variant": variant,
        "data-type": type,
        disabled:
          Component === "button" || Component === "input"
            ? disabled
            : undefined,
        "aria-disabled": disabled ? true : undefined,
        onClick: handleClick,
        type: isSubmit ? "submit" : "button",
        ...rest,
      },
      <>
        {leading && leadingIcon}
        {children}
        {trailing && trailingIcon}
        {loading && <Loading />}
      </>,
    );
  },
);

Button.displayName = "Button";
