import globalData from "../../dictionary/global.json";

type SlideData = {
  IMAGE_URL: string;
  HEADING: string;
  DESCRIPTION: string;
  BUTTON_TEXT: string;
};

export const slides = Array.from({ length: 3 }).map((_, index) => {
  const slideNumber = index + 1;
  const slideKey =
    `SLIDE_0${slideNumber}` as keyof typeof globalData.HOME_PAGE.SLIDES;
  const slideData = globalData.HOME_PAGE.SLIDES[slideKey] as SlideData;

  return {
    imageUrl: slideData.IMAGE_URL,
    heading: slideData.HEADING,
    description: slideData.DESCRIPTION,
    buttonText: slideData.BUTTON_TEXT,
  };
});
