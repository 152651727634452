import cn from "classnames";

export const Skeleton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn("bg-primary/10 animate-pulse rounded-md bg-gray-400", className)}
      {...props}
    />
  );
};
