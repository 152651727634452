import { motion } from "framer-motion";
import globalData from "../../../dictionary/global.json";
import { Badge, SectionHeader } from "../../ui";
import { ContactForm } from "./contact-form";
import { container, item } from "../../../lib/utils/motion";

export const ContactUs = () => {
  return (
    <motion.section
      className="px-4 py-16 md:py-24 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <div className="flex flex-col justify-between gap-4 md:flex-row">
          <motion.div className="flex flex-col gap-3 md:gap-6" variants={item}>
            <Badge
              text={globalData?.CONTACT_US_PAGE?.BANNER_TEXT}
              className="justify-center md:justify-start"
            />
            <SectionHeader
              title={globalData?.CONTACT_US_PAGE?.HEADING}
              description={globalData?.CONTACT_US_PAGE?.DESCRIPTION}
              className="md:!items-start md:!text-left"
              // titleClassName="!text-[44px]"
            />
            <ContactForm />
          </motion.div>
          <motion.div className="hidden w-[45%] md:flex" variants={item}>
            <img src="/assets/images/contact-form.png" alt="contact" className='object-cover' />
          </motion.div>
        </div>
      </motion.div>
    </motion.section>
  );
};
