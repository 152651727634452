import { CsvEditor, CsvFileUploader } from '../../components/sections';
import { useCsvContext } from '../../context';

export const CSVManager = ({ selectedType, provinceId }: { selectedType: ('court-fee-csv' | 'suit-valuation-csv' | ''), provinceId: string | null }) => {
  const { csvData } = useCsvContext();

  return (
    <div className=" sm:py-12  h-screen  ">
      <div className="relative h-full">
        <div className="relative p-5 shadow-xl h-full overflow-auto w-[80vw]">
          <h1 className="text-4xl font-extrabold text-center mb-6 text-primary-solid">CSV File Editor</h1>
          {!csvData[selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation']?.length ? (
            <CsvFileUploader selectedType={selectedType === 'court-fee-csv' ? 'courtFee' : 'suitValuation'} />
          ) : (
            <CsvEditor provinceId={provinceId} selectedType={selectedType} />
          )}
        </div>
      </div>
    </div>
  );
};
