import { Badge, BenefitCard, SectionHeader } from "../../ui";
import globalData from "../../../dictionary/global.json";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";

export const OurMission = () => {
  return (
    <motion.section
      className="px-4 py-16 md:py-24 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <Badge text="Our Mission" className="mb-6 justify-center md:justify-start" />
        <div className="flex flex-col gap-12">
          <motion.div className="flex gap-[72px] flex-col md:flex-row" variants={item}>
            <SectionHeader
              title={globalData?.ABOUT_US_PAGE?.MISSION?.TITLE}
              description={globalData?.ABOUT_US_PAGE?.MISSION?.DESCRIPTION}
              className="flex-1 md:!items-start md:!text-left"
              titleClassName="!text-center md:!text-left"
            />
            <div className="flex flex-col gap-6">
              {globalData?.ABOUT_US_PAGE?.MISSION?.OUR_BENEFITS.map(
                (benefit, index) => (
                  <BenefitCard
                    key={index}
                    type="about"
                    iconSrc={benefit.ICON_SRC}
                    title={benefit.HEADER}
                    description={benefit.SECTION}
                  />
                ),
              )}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.section>
  );
};
