import { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetCountriesQuery,
  useGetMinorCategoryQuery,
  useGetSubMinorCategoryQuery,
  useGetUserActionQuery,
  useLazyGetCalculationQuery,
  useLazyMakeCalculationQuery,
} from "../../../redux/rtk/categoryQuery";
import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Dropdown, Input, Label } from "../../ui";
import globalData from "../../../dictionary/global.json";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CategoryContainer } from "../../ui/category/container";
import { FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../lib/utils/capitalize-first-letter";
import { useMediaQuery } from "../../../lib/hooks";
import { getPriceOriginText } from "../../../lib/utils/get-price-origin-text";

export const CalculationForm: FC<{
  pageType: "courtFee" | "suitValuation";
}> = ({ pageType }) => {
  const [type, setType] = useState<"courtFee" | "suitValuation">("courtFee");
  const [amountError, setAmountError] = useState<string | null>("");
  const { id: calculationId } = useParams();
  const navigate = useNavigate();
  const { width = 768 } = useMediaQuery();
  const NextCalculationText =
    width <= 768 ? "Next Calculation" : "Make Your Next Calculation";

  const searchHistoryText =
    width <= 768 ? "Search History" : "Checkout Search History";

  const [country, setCountry] = useState<{
    id: string;
    name: string;
    provinces: {
      id: string;
      name: string;
    }[];
  }>();

  const [province, setProvince] = useState<{
    id: string;
    name: string;
  } | null>();

  const [selectedCategory, setSelectedCategory] = useState<{
    name: string;
    id: string;
  } | null>(null);

  const [selectedSubCategory, setSelectedSubCategory] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [selectedAmount, setSelectedAmount] = useState<number | null>();

  const [defaultCalculationData, setDefaultCalculationData] = useState<{
    courtFeeToBeAffixed: string;
    result: string;
    reference: string;
  } | null>(null);

  const [selectedSubMinorCategory, setSelectedSubMinorCategory] = useState<{
    name: string;
    id: string;
  } | null>(null);

  const [calculation, setCalculation] = useState<{
    result: number;
    courtFeeToBeAffixed: number;
    referenceToSource: string;
    extraResultInfo?: string;
    type?: string;
  }>();

  const {
    data: countriesData,
    error: countriesError,
    isLoading: countriesIsLoading,
  } = useGetCountriesQuery(null);
  const {
    data: categoryData,
    error: categoryError,
    isLoading: categoryIsLoading,
  } = useGetCategoriesQuery(
    !calculationId && country?.id?.length && province?.id?.length
      ? {
          country: country?.id.toLowerCase() || "",
          province: province?.id.toLowerCase() || "",
          type,
        }
      : skipToken,
  );
  const {
    data: subCategoryData,
    error: subCategoryError,
    isLoading: subCategoryIsLoading,
  } = useGetMinorCategoryQuery(
    !calculationId && selectedCategory && province
      ? {
          country: country?.id.toLowerCase() || "",
          province: province.id.toLowerCase() || "",
          type,
          mainCategoryId: selectedCategory.id || "",
        }
      : skipToken,
  );
  const {
    data: subMinorCategoryData,
    error: subMinorCategoryError,
    isLoading: subMinorCategoryIsLoading,
  } = useGetSubMinorCategoryQuery(
    !calculationId && selectedCategory && selectedSubCategory && province
      ? {
          country: country?.id.toLowerCase() || "",
          province: province.id.toLowerCase() || "",
          type,
          mainCategoryId: selectedCategory.id || "",
          minorCategoryId: selectedSubCategory.id,
        }
      : skipToken,
  );

  const {
    data: userActionData,
    error: userActionError,
    isLoading: userActionIsLoading,
  } = useGetUserActionQuery(
    !calculationId &&
      selectedSubMinorCategory &&
      selectedCategory &&
      selectedSubCategory &&
      country &&
      province
      ? {
          country: country.id.toLowerCase(),
          province: province.id.toLowerCase(),
          type,
          mainCategoryId: selectedCategory.id || "",
          minorCategoryId: selectedSubCategory.id,
          subMinorCategoryId: selectedSubMinorCategory.id,
        }
      : skipToken,
  );

  const [calculationTrigger, { data: calculatedData }] =
    useLazyMakeCalculationQuery();
  const [getCalculationTrigger, { data: calculationData }] =
    useLazyGetCalculationQuery();

  useEffect(() => {
    console.log('userActionData Updated', userActionData)
    if (typeof userActionData?.userAction === "number") {
      setSelectedAmount(userActionData?.userAction);
    } else {
      setSelectedAmount(userActionData?.userAction?.minimumInputValue || 0);
    }

    if (userActionData?.userAction && typeof userActionData?.userAction !== "number") {
      if (typeof userActionData.userAction.courtFeeToBeAffixed === "string") {
        if (typeof userActionData.userAction.result === "string") {
          setDefaultCalculationData({
            reference: userActionData.userAction.reference,
            courtFeeToBeAffixed: userActionData.userAction.courtFeeToBeAffixed,
            result: userActionData.userAction.result
          });
        } else {
          setDefaultCalculationData(null);
        }
      } else {
        setDefaultCalculationData(null);
      }
    } else {
      setDefaultCalculationData(null);
    }
  }, [userActionData]);

  const handleCountryChange = (name: string) => {
    const countryData = countriesData?.countries.find(
      (cD) => cD.name.toLowerCase() === name.toLowerCase(),
    );
    if (countryData) {
      setCountry(countryData);
    }
  };
  const handleProvinceChange = (label: string) => {
    const provinceData = country?.provinces.find(
      (pD) => pD.name.toLowerCase() === label.toLowerCase(),
    );
    setProvince(provinceData);
  };

  const makeCalculation = () => {
    if (
      selectedCategory &&
      selectedSubCategory &&
      selectedSubMinorCategory &&
      userActionData?.userAction &&
      selectedAmount &&
      country &&
      province &&
      type &&
      !amountError
    ) {
      calculationTrigger({
        country: country.id.toLowerCase(),
        province: province.id.toLowerCase(),
        type,
        mainCategoryId: selectedCategory.id || "",
        minorCategoryId: selectedSubCategory.id,
        subMinorCategoryId: selectedSubMinorCategory.id,
        amount: selectedAmount,
      });
    }
  };

  useEffect(() => {
    if (!calculatedData?.id) {
      setSelectedAmount(null);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setSelectedSubMinorCategory(null);
      setCalculation(undefined);
      setType(pageType);
    }
  }, [pageType]);

  useEffect(() => {
    if (calculatedData?.id) {
      setCalculation(calculatedData.calculation);
    }
  }, [calculatedData]);

  useEffect(() => {
    if (calculatedData?.id) {
      navigate(`/calculate/${calculatedData.id}`, {
        replace: true,
      });
    }
  }, [calculation]);

  useEffect(() => {
    if (calculationId) {
      getCalculationTrigger({
        id: calculationId,
      });
    }
  }, []);

  useEffect(() => {
    if (calculationData?.id) {
      setCountry({
        id: calculationData.country,
        name: calculationData.country,
        provinces: [],
      });
      setProvince({
        name: calculationData.province,
        id: "TODO: CHANGE"
      });
      setSelectedAmount(calculationData.amount);
      setSelectedCategory(calculationData.category);
      setSelectedSubCategory(calculationData.minorCategory);
      setSelectedSubMinorCategory(calculationData.subMinorCategory);
      setCalculation(calculationData.calculation);
    }
  }, [calculationData]);

  const avNothingToAddFilters = ["Nothing to be added"];

  return (
    <div className="flex w-full flex-col gap-16 md:p-4">
      <div className="flex w-full gap-4 flex-col md:flex-row md:gap-16">
        <div className="flex w-full flex-col justify-center gap-5 md:max-w-md md:justify-between md:flex-1">
          <h3 className="font-medium text-fg-text-contrast md:text-lg">
            {globalData?.CALCULATE?.FORM?.TITLE}
          </h3>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1.5 md:flex-col">
              <Label className="min-w-24 text-[14px] !font-bold md:min-w-36 md:!text-lg">
                {globalData?.CALCULATE?.FORM?.COUNTRY_INPUT_LABEL}{" "}
                <span className="text-red-600">*</span>
              </Label>
              <Dropdown
                disabled={calculationId ? true : false}
                placeholder={
                  globalData?.CALCULATE?.FORM?.COUNTRY_INPUT_PLACEHOLDER
                }
                className={"text-xs md:text-[16px]"}
                options={
                  countriesData?.countries.map((c) => ({
                    label: c.name,
                  })) || []
                }
                selectedValue={capitalizeFirstLetter(
                  country?.name || "Select Country",
                )}
                onChange={handleCountryChange}
                menuWidth="!w-full"
              />
            </div>
            <div className="flex flex-col gap-1.5 md:flex-col">
              <Label className="min-w-24 text-[14px] !font-bold md:min-w-36 md:!text-lg">
                {globalData?.CALCULATE?.FORM?.PROVINCE_INPUT_LABEL}{" "}
                <span className="text-red-600">*</span>
              </Label>
              <Dropdown
                disabled={calculationId ? true : false}
                placeholder={
                  globalData?.CALCULATE?.FORM?.PROVINCE_INPUT_PLACEHOLDER
                }
                className={"text-xs md:text-[16px]"}
                options={
                  country?.provinces.map((p) => ({
                    label: p.name,
                  })) || []
                }
                selectedValue={capitalizeFirstLetter(
                  province?.name || "Select Province",
                )}
                onChange={handleProvinceChange}
                menuWidth="!w-full"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <Label className="min-w-36 text-[14px] !font-bold md:!text-lg">
                {globalData?.CALCULATE?.FORM?.CATEGORY_INPUT_LABEL}{" "}
                <span className="text-red-600">*</span>
              </Label>
              <CategoryContainer
                disabled={
                  calculationId ||
                  !country ||
                  !province ||
                  !categoryData?.categories?.length ||
                  calculatedData
                    ? true
                    : false
                }
                categories={categoryData?.categories || []}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subCategories={subCategoryData?.categories || []}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                subMinorCategories={subMinorCategoryData?.categories || []}
                selectedSubMinorCategory={selectedSubMinorCategory}
                setSelectedSubMinorCategory={setSelectedSubMinorCategory}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <Label className="min-w-36 !text-[14px] !font-bold md:!text-lg">
                {globalData?.CALCULATE?.FORM?.AMOUNT_INPUT_LABEL}
                <span className="text-red-600">*</span>{" "}
                {typeof userActionData?.userAction !== "number" &&
                  userActionData?.userAction?.name && (
                    <p className="text-sm font-normal text-red-600">
                      ({userActionData?.userAction?.name})
                    </p>
                  )}
              </Label>
              <Input
                contentEditable={false}
                placeholder={
                  globalData?.CALCULATE?.FORM?.AMOUNT_LABEL_PLACEHOLDER
                }
                className={`h-9 w-72 !text-xs !font-normal md:h-11 md:!text-base ${amountError ? "outline-red-500" : ""}`}
                value={selectedAmount || undefined}
                type="number"
                min={
                  userActionData?.userAction
                    ? typeof userActionData?.userAction === "number"
                      ? 0
                      : userActionData?.userAction?.minimumInputValue
                    : 0
                }
                max={
                  userActionData?.userAction
                    ? typeof userActionData?.userAction === "number"
                      ? 0
                      : userActionData?.userAction?.maximumInputValue
                    : 0
                }
                onChange={(e) => {
                  let amount = parseFloat(e.target.value);
                  if (typeof amount === 'number') {
                    let error: string | null = null;
                    if (
                      userActionData?.userAction &&
                      typeof userActionData?.userAction !== "number" &&
                      userActionData?.userAction?.minimumInputValue
                    ) {
                      if (
                        amount < userActionData?.userAction?.minimumInputValue
                      ) {
                        // amount =
                        //   userActionData?.userAction?.minimumInputValue || 0;
                        error = error ? error + "Value less than minimum amount" : "Value less than minimum amount"
                      }
                    }
                    if (
                      userActionData?.userAction &&
                      typeof userActionData?.userAction !== "number" &&
                      userActionData?.userAction?.maximumInputValue
                    ) {
                      if (
                        amount > userActionData?.userAction?.maximumInputValue
                      ) {
                        // amount =
                        //   userActionData?.userAction?.maximumInputValue || 0;
                        error = error ? error + "Value greater than maximum amount" : "Value greater than maximum amount"
                      }
                    }
                    setAmountError(error);
                    setSelectedAmount(amount);
                  }
                }}
                disabled={
                  calculationId || defaultCalculationData ||
                  typeof userActionData?.userAction === "number"
                    ? true
                    : avNothingToAddFilters.includes(userActionData?.userAction?.name || "")
                }
              />
            </div>
          </div>
          {calculationId ? null : (
            <Button
              variant="primary"
              type="solid"
              size="medium"
              onClick={makeCalculation}
              disabled={
                !selectedCategory ||
                !selectedSubCategory ||
                !selectedSubMinorCategory ||
                defaultCalculationData
                  ? true
                  : false
              }
            >
              {globalData?.CALCULATE?.FORM?.BUTTON_TEXT}
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-5 rounded-lg border border-gray-700 bg-white p-4 shadow hover:bg-gray-100 md:flex-1 md:h-max">
          <h3 className="text-xl font-bold text-fg-text-contrast">
            {globalData?.REQUIRED_CALCULATION?.FORM?.TITLE}
          </h3>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex gap-6">
                <Label className="w-52 flex-shrink-0 text-sm !font-medium italic underline sm:w-64 md:text-lg lg:w-80">
                  {type === "courtFee"
                    ? globalData?.REQUIRED_CALCULATION?.FORM
                        ?.SUIT_INPUT_LABEL_COURT_FEE
                    : globalData?.REQUIRED_CALCULATION?.FORM
                        ?.SUIT_INPUT_LABEL_SUIT_VALUATION}
                </Label>
                <p className="flex items-start text-base italic text-black md:text-xl">
                  {calculation?.type === "fixed"
                    ? "Fixed"
                    : `${calculation?.result ? calculation?.result : defaultCalculationData ? defaultCalculationData.result : ""} ${ calculation?.extraResultInfo ? ` ${calculation?.extraResultInfo}` : "" }`}
                </p>
              </div>
              {type === "courtFee" ? (
                <div className="flex gap-6">
                  <Label className="w-52 flex-shrink-0 text-sm !font-medium italic underline sm:w-64 md:text-lg lg:w-80">
                    {globalData?.REQUIRED_CALCULATION?.FORM?.COURT_INPUT_LABEL}
                  </Label>
                  <p className="flex items-start text-base italic text-black md:text-xl">
                    {calculation?.courtFeeToBeAffixed !== undefined ? `${calculation?.courtFeeToBeAffixed} (${getPriceOriginText(country?.name || 'Pakistan')})` : defaultCalculationData ? defaultCalculationData.courtFeeToBeAffixed : ""}
                  </p>
                </div>
              ) : null}
            </div>
            <div className="flex items-start gap-6 md:col-span-2">
              <Label className="w-52 flex-shrink-0 text-sm !font-medium italic underline sm:w-64 md:text-lg lg:w-80">
                {globalData?.REQUIRED_CALCULATION?.FORM?.REFERENCE_INPUT_LABEL}
              </Label>
              <p className=" items-center text-base italic text-black md:text-xl">
                {calculation?.referenceToSource ? calculation.referenceToSource : defaultCalculationData ? defaultCalculationData.reference : ""}
              </p>
            </div>
          </div>
          <div className="mt-4 flex w-full justify-between">
            {calculationId && (
              <Link to={`/calculate?type=${type === 'courtFee' ? 'courtFee' : 'suitValuation'}`} reloadDocument={true} className="flex items-center justify-center text-xs text-primary-text-contrast md:text-base">
                <p>{NextCalculationText}</p>
                <MdKeyboardArrowRight />
              </Link>
            )}
            <Link to="/search-history" className="flex items-center justify-center text-xs text-primary-text-contrast md:text-base">
              <p>{searchHistoryText}</p>
              <MdKeyboardArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
