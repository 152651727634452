import React from "react";
import { Skeleton } from "..";

interface TestimonialCardProps {
  imageUrl: string;
  rating: number;
  testimonial: string;
  className?: string;
  loading?: boolean;
}

export const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imageUrl,
  rating,
  testimonial,
  className,
  loading,
}) => {
  const maxStars = 5;
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = maxStars - filledStars - (hasHalfStar ? 1 : 0);

  return (
    <div
      className={`flex w-[434px] flex-col gap-7 rounded-md border border-[#f3f3f3] bg-bg-default p-5 text-left ${className}`}
    >
      {loading ? (
        <div className="flex flex-col gap-2">
          <Skeleton className="h-12 w-12 !rounded-full" />
          <Skeleton className="h-4 w-48" />
          <Skeleton className="h-4 w-80" />
          <Skeleton className="h-4 w-96" />
        </div>
      ) : (
        <>
          <div className="flex items-start justify-between">
            <img
              className="h-[70px] w-[70px] rounded object-cover"
              src={imageUrl}
              alt="Customer Avatar"
            />
            <div className="flex flex-row gap-1">
              {[...Array(filledStars)].map((_, index) => (
                <img
                  key={index}
                  src="/assets/icons/testimonial/filled-star.svg"
                  alt="filled star"
                  width={32}
                  height={32}
                />
              ))}
              {hasHalfStar && (
                <img
                  src="/assets/icons/testimonial/half-filled-star.svg"
                  alt="half filled star"
                  width={32}
                  height={32}
                />
              )}
              {[...Array(emptyStars)].map((_, index) => (
                <img
                  key={index + filledStars + (hasHalfStar ? 1 : 0)}
                  src="/assets/icons/testimonial/empty-star.svg"
                  alt="empty star"
                  width={32}
                  height={32}
                />
              ))}
            </div>
          </div>
          <p className="text-lg font-medium text-fg-text">{testimonial}</p>
        </>
      )}
    </div>
  );
};
