import { slides } from "../../../lib/constants/slides";
import { AutoPlaySlider } from "../../ui";

export const Hero = () => {
  return (
    <section className="h-screen">
      <AutoPlaySlider slides={slides} interval={5000} />
    </section>
  )
};
