import { Input, Label } from "../index";

interface FormInputProps {
  label: string;
  placeholder: string;
  type?: "text" | "password" | "email";
  isPassword?: boolean;
  isPasswordVisible?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  togglePasswordVisibility?: (e: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  type = "text",
  isPassword,
  isPasswordVisible,
  labelClassName,
  inputClassName,
  togglePasswordVisibility,
  onChange
}) => (
  <div className={`flex flex-col gap-2 ${isPassword ? "relative" : ""}`}>
    <Label className={labelClassName}>{label}</Label>
    <Input type={type} placeholder={placeholder} className={inputClassName} onChange={onChange} />
    {isPassword && (
      <div className="absolute bottom-[0.75rem] right-3.5 text-fg-text">
      </div>
    )}
  </div>
);
