export const getFormattedDate = () => {
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today: Date = new Date();
  const dayOfWeek: string = daysOfWeek[today.getDay()];
  const month: string = monthsOfYear[today.getMonth()];
  const dayOfMonth: number = today.getDate();
  const year: number = today.getFullYear();
  const formattedDate: string = `Today - ${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;
  return formattedDate;
};
