import { ContactUs } from "../../components/sections";
import { HeadBanner } from "../../components/ui";
import globalData from "../../dictionary/global.json";

export const Contact = () => {
  return (
    <>
      <section className="h-screen">
        <HeadBanner
          title={globalData?.CONTACT_US_PAGE?.TITLE}
          imgUrl={globalData?.CONTACT_US_PAGE?.IMAGE_URL}
        />
      </section>
      <ContactUs />
    </>
  );
};
