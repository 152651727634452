import globalData from "../../../dictionary/global.json";
import { Badge, SectionHeader, TestimonialCard } from "../../ui";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";

export const Testimonials = () => {
  return (
    <motion.section
      className="py-24"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <Badge
          text={globalData?.TESTIMONIAL?.BADGE_TEXT}
          className="mb-6 justify-center"
        />
        <div className="flex flex-col gap-12">
          <SectionHeader
            title={globalData?.TESTIMONIAL?.TITLE}
            description={globalData?.TESTIMONIAL?.DESCRIPTION}
            className="mx-auto text-center"
          />
          <motion.div
            className="flex flex-row flex-wrap items-center justify-center md::justify-between gap-4 xl:flex-nowrap"
            variants={item}
          >
            {globalData.TESTIMONIAL?.OUR_TESTIMONIALS.map(
              (testimonial, index) => (
                <TestimonialCard
                  key={index}
                  loading={false}
                  imageUrl={testimonial.imageUrl}
                  rating={testimonial.rating}
                  testimonial={testimonial.testimonial}
                />
              ),
            )}
          </motion.div>
        </div>
      </motion.div>
    </motion.section>
  );
};
