import React from "react";
import { Skeleton } from "..";

interface TeamCardProps {
  imgSrc: string;
  name: string;
  contact: string;
  loading?: boolean;
}

export const TeamCard: React.FC<TeamCardProps> = ({
  imgSrc,
  name,
  contact,
  loading,
}) => {
  return (
    <div className="flex w-[276px] flex-col items-center gap-4 text-center">
      {loading ? (
        <>
          <Skeleton className="h-12 w-12 !rounded-full" />
          <Skeleton className="h-4 w-[150px]" />
          <Skeleton className="h-4 w-[220px]" />
        </>
      ) : (
        <>
          <img src={imgSrc} alt="dp" className="rounded-3xl" />
          <div className="text-lg font-bold">{name}</div>
          <div className="text-lg text-fg-text">{contact}</div>
        </>
      )}
    </div>
  );
};
