import { LinkItem } from "./link-item";

type LinkSectionProps = {
  heading: string;
  links: Array<{ HREF: string; NAME: string }>;
};

export const LinkSection: React.FC<LinkSectionProps> = ({ heading, links }) => (
  <div className="flex flex-col gap-5">
    <h3 className="text-[14px] md:text-[16px] flex h-8 w-fit items-center justify-center rounded-md px-2.5 text-center font-bold text-bg-base">
      {heading}
    </h3>
    <ul className="flex flex-col gap-3 text-bg-bg">
      {links.map((link, index) => (
        <LinkItem key={index} linkData={link} />
      ))}
    </ul>
  </div>
);
