import React from "react";
import { Skeleton } from "..";

interface HeadBannerProps {
  className?: string;
  loading?: boolean;
  imgUrl: string;
  title: string;
}

export const HeadBanner: React.FC<HeadBannerProps> = ({
  className,
  loading,
  title,
  imgUrl,
}) => {
  return (
    <div
      className={`justify-between' relative flex h-full w-full items-center ${className} `}
    >
      {loading ? (
        <>
          <Skeleton className="h-4 w-96" />
          <Skeleton className="h-4 w-40" />
        </>
      ) : (
        <>
          <img
            src={imgUrl}
            alt="about banner"
            className="h-full w-full object-cover"
            style={{ filter: "brightness(0.5)" }}
          />
          <h1 className="absolute left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] text-5xl font-bold text-bg-base">
            {title}
          </h1>
        </>
      )}
    </div>
  );
};
