import React from "react";
import { Skeleton } from "..";

interface ServiceCardProps {
  num: string;
  title: string;
  description: string;
  className?: string;
  loading?: boolean;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  num,
  title,
  description,
  className,
  loading,
}) => {
  return (
    <div className={`flex w-[350px] flex-col gap-5 text-left ${className}`}>
      {loading ? (
        <>
          <Skeleton className="h-6 w-16" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-5 w-44" />
            <Skeleton className="h-4 w-80" />
            <Skeleton className="h-4 w-72" />
          </div>
        </>
      ) : (
        <>
          <h3 className="text-6xl font-bold text-primary-solid">{num}</h3>
          <div className="flex flex-col gap-2">
            <h4 className="text-2xl font-bold text-fg-text-contrast">
              {title}
            </h4>
            <p className="text-lg text-fg-text ">{description}</p>
          </div>
        </>
      )}
    </div>
  );
};
