import globalData from "../../../dictionary/global.json";
import { Badge, Button, SectionHeader } from "../../ui";

export const CalculatorAdvantage = () => {
  return (
    <section className="px-4 py-16 md:py-24 lg:px-0">
      <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-16 md:flex-row md:items-start">
        <div className="flex flex-col gap-6">
          <Badge
            text={globalData?.HOME_PAGE?.CALCULATOR_ADVANTAGE?.BANNER_TEXT}
            className="justify-center md:!justify-start"
          />
          <SectionHeader
            className="md:!items-start md:!text-left"
            title={globalData?.HOME_PAGE?.CALCULATOR_ADVANTAGE?.HEADING}
            description={globalData?.HOME_PAGE?.CALCULATOR_ADVANTAGE?.DESCRIPTION.map(
              (item, index) => (
                <p key={index} className="mb-4">
                  {item.PARAGRAPH}
                </p>
              ),
            )}
            imageUrl="/assets/images/calculator-advantage.png"
            imageClasses="flex md:hidden w-[400px]"
            titleClassName="!text-center md:!text-left"
          />
          <Button
            variant="primary"
            type="solid"
            size="large"
            className="self-center md:self-start"
          >
            {globalData?.HOME_PAGE?.CALCULATOR_ADVANTAGE?.BUTTON_TEXT}
          </Button>
        </div>
        <div className="hidden md:flex">
          <img
            src="/assets/images/calculator-advantage.png"
            alt="calculator advantage"
          />
        </div>
      </div>
    </section>
  );
};
