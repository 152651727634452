"use client";
import React, { useState, useEffect } from "react";
import { Button } from "..";
import styles from "./auto-play-slider.module.css";
interface Slide {
  imageUrl: string;
  heading: string;
  description?: string;
  buttonText?: string;
}
interface AutoPlaySliderProps {
  slides: Slide[];
  interval?: number;
}

export const AutoPlaySlider: React.FC<AutoPlaySliderProps> = ({
  slides,
  interval = 3000,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const updateProgress = () => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (100 / interval) * 50;
        if (newProgress >= 100) {
          const nextSlide = (currentSlide + 1) % slides.length;
          setProgress(0);
          setCurrentSlide(nextSlide);
          return 0;
        }
        return newProgress;
      });
    };
    let progressInterval = setInterval(updateProgress, 50);
    if (isHovering) {
      clearInterval(progressInterval);
    }
    return () => clearInterval(progressInterval);
  }, [currentSlide, interval, isHovering, slides.length]);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => {
    setIsHovering(false);
    if (progress >= 100) {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      setProgress(0);
    }
  };

  return (
    <div
      className="relative h-full w-full overflow-hidden bg-primary-text-contrast"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`pointer-events-none absolute inset-0 flex select-none items-center justify-center transition-opacity duration-700 ${
            index === currentSlide ? "z-20 opacity-100" : "z-10 opacity-0"
          }`}
        >
          <img
            src={slide.imageUrl}
            alt={slide.description || ""}
            className="pointer-events-none !h-full w-full select-none object-cover"
            style={{ filter: "brightness(0.5)" }}
          />
          <div className="absolute flex w-full flex-col justify-center gap-6 p-3 text-center text-bg-base lg:w-1/2">
            {slide.heading && (
              <div
                className={`w-full text-bg-base transition-opacity duration-700 ${
                  index === currentSlide ? "opacity-100" : "opacity-0"
                }`}
                style={{
                  transitionDelay: "1s",
                }}
              >
                <h1 className="text-3xl font-bold md:text-5xl">
                  {slide.heading}
                </h1>
              </div>
            )}
            {slide.description && (
              <div
                className={`mx-auto w-full transform text-bg-base transition-opacity duration-700 md:w-4/5 ${
                  index === currentSlide ? "opacity-100" : "opacity-0"
                }`}
                style={{
                  transitionDelay: "1s",
                }}
              >
                <p className="sm:text-lg text-primary-bg-subtle md:text-xl">
                  {slide.description}
                </p>
              </div>
            )}
            <div
              className={`flex transform justify-center transition-opacity duration-700 ${
                index === currentSlide ? "opacity-100" : "opacity-0"
              }`}
              style={{
                transitionDelay: "1s",
              }}
            >
              <Button
                variant="gray"
                type="outline"
                size="medium"
                className={`pointer-events-auto !border-fg-solid !text-sm !text-bg-base md:text-lg ${styles.button}`}
              >
                {slide.buttonText}
              </Button>
            </div>
          </div>
        </div>
      ))}
      <div className="absolute bottom-5 left-0 right-0 z-30 mx-auto mb-12 flex w-2/5 justify-center gap-4 space-x-2 px-4">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => {
              setCurrentSlide(index);
              setProgress(0);
            }}
            className={`cursor-pointer relative h-2 ${index === currentSlide ? "w-full" : "w-3/5"} rounded-lg border border-bg-base bg-transparent transition-all duration-700 ease-in-out`}
          >
            <div
              className="absolute left-0 top-0 h-full bg-bg-base"
              style={{
                width: `${index === currentSlide ? progress : 0}%`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};
