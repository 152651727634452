import { Button, Input } from "../../../ui";
import globalData from "../../../../dictionary/global.json";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { LiaFaxSolid } from "react-icons/lia";
import { HiOutlineMailOpen } from "react-icons/hi";
import { ContactCta } from "../contact-cta";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const contactDetails = [
  {
    icon: <MdOutlinePhoneInTalk className="text-3xl" />,
    title: "Phone",
    description: "045 2301 2301",
  },
  {
    icon: <HiOutlineMailOpen className="text-3xl" />,
    title: "Email",
    description: "info@marcc.com.au",
  },
  {
    icon: <LiaFaxSolid className="text-3xl" />,
    title: "Fax",
    description: "03 5432 1234",
  },
];

export const ContactForm = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <Input
        placeholder={globalData?.CONTACT_US_PAGE?.FORM?.NAME_INPUT_PLACEHOLDER}
        type="text"
      />
      <Input
        placeholder={globalData?.CONTACT_US_PAGE?.FORM?.EMAIL_INPUT_PLACEHOLDER}
        type="email"
      />
      <PhoneInput country={"us"} value={""} onChange={() => {}} />
      <textarea
        placeholder={
          globalData?.CONTACT_US_PAGE?.FORM?.MESSAGE_INPUT_PLACEHOLDER
        }
        rows={4}
        className="w-full rounded-md border border-secondary-solid bg-transparent p-3.5 text-left text-sm font-medium text-fg-text-contrast outline-none placeholder:text-secondary-solid focus:border-fg-border disabled:cursor-not-allowed disabled:opacity-50 md:text-base"
      />
      <Button variant="primary" type="solid" size="medium" className="my-2">
        {globalData?.CONTACT_US_PAGE?.FORM?.BUTTON_TEXT}
      </Button>
      <div className="flex flex-col-reverse justify-between md:flex-row gap-6 md:gap-0">
        {contactDetails.map((contact, index) => (
          <ContactCta
            key={index}
            icon={contact.icon}
            title={contact.title}
            description={contact.description}
          />
        ))}
      </div>
    </div>
  );
};
