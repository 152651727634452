import { OurMission, OurTeam } from "../../components/sections";
import { ContactBanner, HeadBanner } from "../../components/ui";
import globalData from "../../dictionary/global.json";

export const AboutUs = () => {
  return (
    <>
      <section className="h-screen">
        <HeadBanner
          title={globalData?.ABOUT_US_PAGE.TITLE}
          imgUrl={globalData.ABOUT_US_PAGE.IMAGE_URL}
        />
      </section>
      <OurMission />
      <OurTeam />
      <ContactBanner className="pb-16 pt-12 md:pb-24" />
    </>
  );
};
