import globalData from "../../../dictionary/global.json";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";
import { LinkSection } from "./link-section";

export const Footer = () => {
  const year = new Date().getFullYear();
  const convertSectionLinks = (
    section: any,
  ): Array<{ HREF: string; NAME: string }> => {
    return Object.values(section).filter(
      (item) => typeof item === "object",
    ) as Array<{ HREF: string; NAME: string }>;
  };

  return (
    <motion.section
      className="flex min-h-96 flex-col items-center justify-end gap-12 bg-[#242323] py-5 px-4 lg:px-0"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-4">
        <motion.div
          className="flex flex-col gap-5 md:flex-row lg:gap-56"
          variants={item}
        >
          <div className="flex flex-1 flex-col gap-8 px-2.5 md:px-0">
            <img
              alt="logo"
              src="/assets/images/secondary-logo.png"
              className="w-[137px]"
            />
            <p className="text-[12px] text-bg-base md:text-[16px]">
              {globalData?.FOOTER?.TEXT}
            </p>
          </div>
          <div className="flex flex-1 justify-between">
            <LinkSection
              heading={globalData?.FOOTER?.TERMS_AND_POLICIES?.HEADING}
              links={convertSectionLinks(
                globalData?.FOOTER?.TERMS_AND_POLICIES,
              )}
            />
            <LinkSection
              heading={globalData?.FOOTER?.WEBSITE?.HEADING}
              links={convertSectionLinks(globalData?.FOOTER?.WEBSITE)}
            />
            <LinkSection
              heading={globalData?.FOOTER?.FOLLOW_US?.HEADING}
              links={convertSectionLinks(globalData?.FOOTER?.FOLLOW_US)}
            />
          </div>
        </motion.div>
      </div>
      <motion.p
        className="md: text-center text-[11px] text-sm text-bg-bg"
        variants={item}
      >
        <span>{globalData?.COPYRIGHT?.TEXT?.[0]}</span>
        <span>{year}</span>
        <span>{globalData?.COPYRIGHT?.TEXT?.[1]}</span>
      </motion.p>
    </motion.section>
  );
};
