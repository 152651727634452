"use client";

import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
  SelectGroup,
  Switch,
} from "../../ui";
import { useDispatch, useSelector } from "react-redux";
import { addCountry, fetchCountries } from "../../../redux/slice/dashboardSlice";
import { AppDispatch, RootState } from "../../../redux/store";

interface SelectCountryDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  onSave: (country: string) => void;
}


export const SelectCountryDialog: FC<SelectCountryDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  onSave
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { countries, loading, error } = useSelector((state: RootState) => state.dashboard);
  const [existingCountry, setExcitingCountry] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [isAddNewActive, setIsAddNewActive] = useState(false);

  const handleSave = () => {
    const countryToSave = isAddNewActive ? newCountry : existingCountry;
    console.log("Saving:", existingCountry || newCountry);
    if (isAddNewActive) {
      dispatch(addCountry(newCountry));
    }
    onSave(countryToSave);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent className="max-w-3xl">
        <div className="flex flex-col gap-8 px-6 py-7">
          <DialogHeader className="!flex-row gap-2 border-none p-0">
            <DialogTitle className="text-lg font-medium text-fg-text-contrast">
              Select country
            </DialogTitle>
          </DialogHeader>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-64 flex-col gap-1">
              <Label
                htmlFor="existingCountry"
                className={
                  isAddNewActive ? "!text-fg-text" : "!text-fg-text-contrast"
                }
              >
                Add existing country
              </Label>
              <Select
                value={existingCountry}
                onValueChange={setExcitingCountry}
                disabled={isAddNewActive}
              >
                <SelectTrigger aria-label="Country">
                  <SelectValue placeholder="Select a country" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {countries.map((country, index) => (
                      <SelectItem key={index} value={country.name}>
                        {country.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <Switch
              checked={isAddNewActive}
              onCheckedChange={setIsAddNewActive}
            />
            <div className="flex w-64 flex-col gap-1">
              <Label
                htmlFor="newCountry"
                className={
                  isAddNewActive ? "!text-fg-text-contrast" : "!text-fg-text"
                }
              >
                Add a new country
              </Label>
              <Input
                id="newCountry"
                value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
                placeholder="Enter a new country name"
                className="!h-10 !border-fg-line !text-sm"
                disabled={!isAddNewActive}
              />
            </div>
          </div>
        </div>
        <DialogFooter className="flex items-center px-6 py-4">
          <div className="flex items-center gap-2">
            <DialogClose>
              <Button
                as="div"
                variant="gray"
                size="small"
                type="outline"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              as="div"
              variant="primary"
              size="small"
              type="solid"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
