import React, { ReactNode } from "react";
import { Skeleton } from "..";

interface SectionHeaderProps {
  title: string;
  description: string | ReactNode;
  loading?: boolean;
  imageUrl?: string;
  imageClasses?: string;
  className?: string;
  titleClassName?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  loading,
  imageUrl,
  imageClasses,
  className,
  titleClassName,
}) => {
  return (
    <>
      {loading ? (
        <div className={`flex flex-col gap-3 ${className}`}>
          <Skeleton className="h-5 w-[800px]" />
          <Skeleton className="h-5 w-[500px]" />
        </div>
      ) : (
        <div
          className={`flex flex-col items-center gap-6 ${className}`}
        >
          <h2
            className={`text-start text-2xl font-bold text-fg-text-contrast md:text-5xl ${titleClassName}`}
          >
            {title}
          </h2>
          {imageUrl ? (
            <div>
              <img
                src={imageUrl}
                alt="calculator advantage"
                className={imageClasses}
              />
            </div>
          ) : null}
          <div
            className={`max-w-[500px] text-center md:!leading-8 text-fg-text md:text-lg ${className}`}
          >
            {description}
          </div>
        </div>
      )}
    </>
  );
};
