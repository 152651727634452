import { useState } from "react";
import { Button, Dropdown } from "../../ui";
import globalData from "../../../dictionary/global.json";
import { PaymentMethods } from "./payment-method";
import { DebitCard } from "./debit-card";
import { FaChevronLeft } from "react-icons/fa6";

export const Payment = () => {
  const [quantity, setQuantity] = useState("Select Quantity");
  const [selectedMethod, setSelectedMethod] = useState(
    "Debit Card/Credit Card",
  );

  const handleQuantityUpdate = (label: string) => {
    setQuantity(label);
  };

  return (
    <section className="mx-auto flex flex-col gap-8 py-16 lg:max-w-7xl">
      <div className="flex flex-row items-center gap-3">
        <FaChevronLeft className="text-2xl text-fg-text-contrast" />
        <p className="text-lg font-bold md:text-3xl">
          {globalData?.PAYMENT_PAGE?.BUY_SEARCHES}
        </p>
      </div>
      <div className="flex flex-col justify-between gap-5 px-8 md:flex-row">
        <div className="flex w-full flex-col gap-6 md:w-[632px]">
          <div className="flex items-center justify-between gap-2">
            <h2 className="text-xs font-medium text-fg-text-contrast md:text-lg">
              {globalData?.PAYMENT_PAGE?.BUY_NUM_OF_SEARCHES}
            </h2>
            <div className="w-56">
              <Dropdown
                placeholder="Select Quantity"
                className={"text-xs md:text-[16px]"}
                options={globalData?.PAYMENT_PAGE?.AVAILABLE_SEARCHES}
                selectedValue={quantity}
                onChange={handleQuantityUpdate}
                menuWidth="!w-56"
              />
            </div>
          </div>
          <h3 className="text-xs font-bold text-fg-text-contrast underline md:text-xl">
            {globalData?.PAYMENT_PAGE?.SELECT_PAYMENT_METHOD}
          </h3>
          <div className="flex max-w-full flex-col gap-4 md:gap-6">
            <PaymentMethods
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
            />
            {selectedMethod === "Debit Card/Credit Card" && <DebitCard />}
            {selectedMethod === "Jazz Cash" && (
              <div>Placeholder for Jazz Cash specific content/form.</div>
            )}
            {selectedMethod === "Easy Paisa" && (
              <div>Placeholder for EasyPaisa specific content/form.</div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-col md:w-[445px]">
          <div className="flex flex-col gap-3 rounded-t-lg border border-fg-text-contrast p-5">
            <h3 className="mb-2 text-sm font-bold md:text-center md:text-3xl">
              {globalData?.PAYMENT_PAGE?.ORDER_SUMMARY}
            </h3>
            <h4 className="font-semibold md:text-xl">
              {globalData?.PAYMENT_PAGE?.ORDER_DESCRIPTION_HEADING}
            </h4>
            <p className="text-xs font-medium md:text-lg md:text-fg-text">
              {globalData?.PAYMENT_PAGE?.ORDER_DESCRIPTION}
            </p>
          </div>
          <div className="flex flex-row gap-3 border border-t-0 border-black bg-[#EDE8E8] p-3 text-center md:flex-col">
            <h4 className="text-xs font-bold md:text-xl">
              {globalData?.PAYMENT_PAGE?.ORDER_DAY}
            </h4>
            <div className="font-medium md:text-xl">
              {globalData?.PAYMENT_PAGE?.ORDER_DATE}
            </div>
          </div>
          <Button
            variant="primary"
            type="outline"
            size="large"
            className="mt-4 w-full !rounded-none"
          >
            {globalData?.PAYMENT_PAGE?.PURCHASE_BUTTON}
          </Button>
        </div>
      </div>
    </section>
  );
};
