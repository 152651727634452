import { Dispatch, FC, SetStateAction } from "react";
import { Button } from "../../../ui";
import globalData from "../../../../dictionary/global.json";
import { AiOutlineCreditCard } from "react-icons/ai";
import { PiMoneyFill } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

interface PaymentMethodProps {
  selectedMethod: string;
  setSelectedMethod: Dispatch<SetStateAction<string>>;
}

export const PaymentMethods: FC<PaymentMethodProps> = ({
  selectedMethod,
  setSelectedMethod,
}) => {
  const getIcon = (methodName: string) => {
    switch (methodName) {
      case "Debit Card/Credit Card":
        return <AiOutlineCreditCard className="text-2xl" />;
      case "Jazz Cash":
        return <PiMoneyFill className="text-2xl" />;
      case "Easy Paisa":
        return <RiMoneyDollarCircleLine className="text-2xl" />;
      default:
        return <AiOutlineCreditCard />;
    }
  };

  return (
    <>
      <div className="flex gap-2 font-bold md:gap-4">
        {globalData?.PAYMENT_PAGE?.PAYMENT_METHODS.map(({ METHOD, ICON }) => (
          <Button
            key={METHOD}
            variant="primary"
            size="large"
            type={selectedMethod === METHOD ? "solid" : "outline"}
            onClick={() => setSelectedMethod(METHOD)}
            className="flex !h-12 items-center gap-2 !text-lg "
          >
            {getIcon(METHOD)}
            {METHOD}
          </Button>
        ))}
      </div>
    </>
  );
};
