import React from "react";
import { Button, Skeleton } from "..";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../../../lib/hooks";

interface ContactBannerProps {
  className?: string;
  loading?: boolean;
}

export const ContactBanner: React.FC<ContactBannerProps> = ({
  className,
  loading,
}) => {
  const { width = 768 } = useMediaQuery();
  const buttonSize = width <= 768 ? "medium" : "large";

  return (
    <div
      className={`mx-auto flex w-full max-w-7xl flex-col items-center justify-between gap-6 px-4 md:flex-row md:items-start lg:px-0 ${className}`}
    >
      {loading ? (
        <>
          <Skeleton className="h-4 w-96" />
          <Skeleton className="h-4 w-40" />
        </>
      ) : (
        <>
          <h3 className="text-[21px] font-bold text-fg-text-contrast md:text-[44px]">
            Have any questions? Feel free to ask!
          </h3>
          <Link to="/contact">
            <Button
              variant="primary"
              size={buttonSize}
              type="solid"
              className="gap-2 text-nowrap !text-[12px] md:!text-[20px]"
            >
              Contact Us <FaArrowRight />
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};
