import { PiCoin, PiHandCoins } from "react-icons/pi";
import { BlackLogo, Button } from "../../ui";
import { Link } from "react-router-dom";

interface SidebarProps {
  isEnabled: string;
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  activeComponent: string
}

export const Sidebar: React.FC<SidebarProps> = ({
  isEnabled,
  setActiveComponent,
  activeComponent,
}) => {
  return (
    <aside className="fixed top-0 flex h-full w-60 flex-col gap-8 rounded-br-md rounded-tr-md border-r border-fg-line bg-bg-bg px-3 py-4 shadow-md">
      <Link to="/">
        <BlackLogo className="px-3.5 max-md:w-28" />
      </Link>
      <div className="flex flex-col gap-4">
        <Button
          variant="gray"
          size="medium"
          type="ghost"
          disabled={!isEnabled}
          className={`flex items-center !justify-start gap-2 ${activeComponent === "courtFee" ? "!bg-primary-bg-hover" : ""}`}
          onClick={() => isEnabled && setActiveComponent("courtFee")}
        >
          <PiCoin />
          <p>Court Fee</p>
        </Button>
        <Button
          variant="gray"
          size="medium"
          type="ghost"
          disabled={!isEnabled}
          className={`flex items-center !justify-start gap-2 ${activeComponent === "suitValuation" ? "!bg-primary-bg-hover" : ""}`}
          onClick={() => isEnabled && setActiveComponent("suitValuation")}
        >
          <PiHandCoins />
          <p>Suit Valuation</p>
        </Button>
        <Button
          variant="gray"
          size="medium"
          type="ghost"
          disabled={!isEnabled}
          className={`flex items-center !justify-start gap-2 ${activeComponent === "courtFeeCSV" ? "!bg-primary-bg-hover" : ""}`}
          onClick={() => isEnabled && setActiveComponent("courtFeeCSV")}
        >
          <PiCoin />
          <p>Court Fee CSV</p>
        </Button>
        <Button
          variant="gray"
          size="medium"
          type="ghost"
          disabled={!isEnabled}
          className={`flex items-center !justify-start gap-2 ${activeComponent === "suitValuationCSV" ? "!bg-primary-bg-hover" : ""}`}
          onClick={() => isEnabled && setActiveComponent("suitValuationCSV")}
        >
          <PiHandCoins />
          <p>Suit Valuation CSV</p>
        </Button>
      </div>
    </aside>
  );
};
