import { Routes, Route, useLocation } from "react-router-dom";
import { Footer, Navbar } from "./components/layout";
import { Payment, SearchHistory } from "./components/sections";
import {
  Home,
  SignUp,
  LogIn,
  Calculate,
  AboutUs,
  Services,
  Contact,
  AdminDashboard,
} from "./pages";
import { ProtectedRoute } from "./components/privateRoute";
import { CheckLoggedIn } from "./components/checkLoggedIn";
import { isExcludedRoute } from "./lib/utils/excluded-route";

function App() {
  const location = useLocation();

  return (
    <>
      {!isExcludedRoute(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/courtFee"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/suitValuation"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/court-fee-csv"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/suit-valuation-csv"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />

        <Route path="/home" element={<Home />} />
        <Route path="/auth/signup" element={<SignUp />} />
        <Route
          path="/auth/login"
          element={
            <CheckLoggedIn>
              <LogIn />
            </CheckLoggedIn>
          }
        />
        <Route
          path="/calculate"
          element={
            <ProtectedRoute>
              <Calculate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/calculate/:id"
          element={
            <ProtectedRoute>
              <Calculate />
            </ProtectedRoute>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/search-history"
          element={
            <ProtectedRoute>
              <SearchHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />

        <Route path="/contact" element={<Contact />} />
      </Routes>
      {!isExcludedRoute(location.pathname) && <Footer />}
    </>
  );
}

export default App;
