import { ServiceCard } from "../../ui";
import globalData from "../../../dictionary/global.json";
import { motion } from "framer-motion";
import { container, item } from "../../../lib/utils/motion";

export const OurServices = () => {
  return (
    <motion.section
      className="py-24"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="mx-auto max-w-7xl" variants={item}>
        <div className="flex flex-row flex-wrap items-center justify-center xl:justify-between gap-8 xl:gap-0">
          {globalData?.SERVICES_PAGE?.OUR_SERVICES.map((service, index) => (
            <ServiceCard
              key={index}
              num={service.NUM}
              title={service.TITLE}
              description={service.DESCRIPTION}
            />
          ))}
        </div>
      </motion.div>
    </motion.section>
  );
};
