import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store'; 
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { refreshTokens } from '../../redux/slice/authSlice';
import { useAppDispatch } from '../../redux/hooks';
import { isTokenExpired } from '../../lib/utils/token';

export const ProtectedRoute = ({ children }: { children: any }) => {
  const { isAuthenticated, tokens, initialTokenRefeshing } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tokens?.access && isTokenExpired(tokens.access.expires)) {
      dispatch(refreshTokens(tokens.refresh));
    }
  }, [tokens, isAuthenticated]);

  if (initialTokenRefeshing || (isAuthenticated && (tokens?.refresh?.token?.length || 0) > 0)) {
    return children;
  }

  return <Navigate to="/auth/login" />;
};
