import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'; 
import { Navigate } from 'react-router-dom';

export const CheckLoggedIn=({children}:{children:any})=>{
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  console.log(isAuthenticated)
  
  if(isAuthenticated){
    return <Navigate to="/calculate" />;
  }
  return children
}