import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../lib/utils/url';
import { CSVCategoryType, CsvContextProps, UploadCSVResponse } from '../../types';

export const CsvContext = createContext<CsvContextProps | undefined>(undefined);

export const CsvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [csvData, setCsvData] = useState<{ suitValuation: CSVCategoryType[]; courtFee: CSVCategoryType[] }>({
    suitValuation: [],
    courtFee: []
  });
  const [editedData, setEditedData] = useState<{ suitValuation: CSVCategoryType[]; courtFee: CSVCategoryType[] }>({
    suitValuation: [],
    courtFee: []
  });
  const [message, setMessage] = useState<string>('');
  const [newColumnName, setNewColumnName] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    setEditedData(csvData);
  }, [csvData]);

  const handleEditChange = (fileType: 'suitValuation' | 'courtFee', rowIndex: number, columnKey: keyof CSVCategoryType, value: string) => {
    const updatedData = { ...editedData };
    updatedData[fileType][rowIndex][columnKey] = value;
    setEditedData(updatedData);
  };

  const uploadCSVToServer = async (csvData: CSVCategoryType[], endpoint: 'add-suit-valuation-csv-data' | 'add-court-fee-csv-data'): Promise<UploadCSVResponse> => {
    try {
      console.log("csv data", csvData)
      const response = await axios.post(`${BASE_URL}/v1/category-data/${endpoint}`, csvData);
      return {
        data: response.data,
        status: true,
        message: 'File uploaded successfully'
      };
    } catch (error) {
      console.error('There was an error uploading the file!', error);
      return {
        data: null,
        status: false,
        message: 'Failed to upload file'
      };
    }
  };

  const handleUploadCSVToSever = async (type: 'court-fee-csv' | 'suit-valuation-csv' | '', provinceId: string | null) => {
    let response: UploadCSVResponse;
    if (provinceId) {
      const fileType = type === 'court-fee-csv' ? 'courtFee' : 'suitValuation';
      const csvData: CSVCategoryType[] = editedData[fileType].map((data: CSVCategoryType) => {
        let activityByUser = data.activityByUser;
        if (!isNaN(Number(data.activityByUser))) {
          activityByUser = Number(data.activityByUser);
        }
        let minimumSuitValuation = data.minimumSuitValuation;
        if (isNaN(Number(minimumSuitValuation))) {
          minimumSuitValuation = 0
        }
        let maximumCourtFee = data.maximumCourtFee;
        if (isNaN(Number(maximumCourtFee))) {
          maximumCourtFee = 0
        }
        let cfUserInput = data.cfUserInput;
        if (isNaN(Number(cfUserInput))) {
          cfUserInput = 0
        }
        let svUserInput = data.svUserInput;
        if (isNaN(Number(svUserInput))) {
          svUserInput = 0
        }

        let defaultCourtFeeToBeAffixed = data.defaultCourtFeeToBeAffixed;
        if (defaultCourtFeeToBeAffixed && !isNaN(Number(defaultCourtFeeToBeAffixed))) {
          defaultCourtFeeToBeAffixed = (defaultCourtFeeToBeAffixed as string).length ? defaultCourtFeeToBeAffixed : null;
        }

        return {
          provinceId,
          majorCategoryType: type === 'court-fee-csv' ? 'courtFee' : 'suitValuation',
          minimumSuitValuation,
          maximumCourtFee,
          cfUserInput,
          cfUserInputType: data.cfUserInputType || "fixed",
          svUserInput,
          svUserInputType: data.svUserInputType,
          minimumUserInput: data.minimumUserInput ? Number(data.minimumUserInput) : 0,
          maximumUserInput: data.maximumUserInput ? Number(data.maximumUserInput) : null,
          defaultResult: data.defaultResult ? data.defaultResult : null,
          extraResultInfo: data.extraResultInfo ? data.extraResultInfo : null,
          defaultCourtFeeToBeAffixed,
          majorCategory: data.majorCategory,
          minorCategory: data.minorCategory,
          subminorCategory: data.subminorCategory,
          activityByUser,
          reference: data.reference,
        }
      });
      console.log('Edited CSV Data', editedData);
      console.log('Mapped CSV Data', csvData);
      setIsUploading(true);
      if (type === 'court-fee-csv') {
        response = await uploadCSVToServer(csvData, 'add-court-fee-csv-data');
        setMessage(response.message);
      } else if (type === 'suit-valuation-csv') {
        response = await uploadCSVToServer(csvData, 'add-suit-valuation-csv-data');
        setMessage(response.message);
      } else {
        setMessage('Failed to upload data');
        setIsUploading(false);
      }
      setIsUploading(false);
      setTimeout(() => {
        setMessage('');
      }, 4000);
    } else {
      setMessage('Province Not Selected');
      setIsUploading(false);
      setTimeout(() => {
        setMessage('');
      }, 4000);
    }
  };

  const addRow = (fileType: 'suitValuation' | 'courtFee') => {
    if (editedData[fileType].length === 0) return;
    const newRow: CSVCategoryType = Object.keys(editedData[fileType][0]).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {} as CSVCategoryType);
    const updatedData = { ...editedData };
    updatedData[fileType] = [...updatedData[fileType], newRow];
    setEditedData(updatedData);
  };

  const addColumn = () => {
    if (!newColumnName.trim()) {
      alert('Please enter a valid column name.');
      return;
    }
    const newColumnKey = newColumnName.trim();
    const updatedData = { ...editedData };
    updatedData.suitValuation = updatedData.suitValuation.map(row => ({ ...row, [newColumnKey]: '' }));
    updatedData.courtFee = updatedData.courtFee.map(row => ({ ...row, [newColumnKey]: '' }));
    setEditedData(updatedData);
    setNewColumnName('');
  };

  const deleteRow = (fileType: 'suitValuation' | 'courtFee', rowIndex: number) => {
    const updatedData = { ...editedData };
    updatedData[fileType] = updatedData[fileType].filter((_, index) => index !== rowIndex);
    setEditedData(updatedData);
  };

  const deleteColumn = (columnKey: keyof CSVCategoryType) => {
    const updatedData = { ...editedData };
    updatedData.suitValuation = updatedData.suitValuation.map(row => {
      const { [columnKey]: _, ...rest } = row;
      return rest as CSVCategoryType;
    });
    updatedData.courtFee = updatedData.courtFee.map(row => {
      const { [columnKey]: _, ...rest } = row;
      return rest as CSVCategoryType;
    });
    setEditedData(updatedData);
  };

  return (
    <CsvContext.Provider
      value={{
        csvData,
        editedData,
        message,
        newColumnName,
        setCsvData,
        setEditedData,
        setMessage,
        setNewColumnName,
        handleEditChange,
        handleUploadCSVToSever,
        addRow,
        addColumn,
        deleteRow,
        deleteColumn,
        isUploading,
        setIsUploading
      }}
    >
      {children}
    </CsvContext.Provider>
  );
};

export const useCsvContext = () => {
  const context = useContext(CsvContext);
  if (!context) {
    throw new Error('useCsvContext must be used within a CsvProvider');
  }
  return context;
};
