import { useState } from "react";
import { Dropdown, FormInput, Label } from "../../../ui";
import globalData from "../../../../dictionary/global.json";

export const DebitCard = () => {
  const [month, setMonth] = useState("Select Month");
  const [year, setYear] = useState("Select Year");
  const [saveCard, setSaveCard] = useState(false);

  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    label: `${i + 1}`.padStart(2, "0"),
  }));

  const currentYear = new Date().getFullYear();
  const yearsBefore = 24;
  const yearsAfter = 15;
  const yearOptions = Array.from(
    { length: yearsBefore + yearsAfter + 1 },
    (_, i) => ({
      label: `${currentYear - yearsBefore + i}`,
    }),
  );

  const handleMonthUpdate = (label: string) => {
    setMonth(label);
  };
  const handleYearUpdate = (label: string) => {
    setYear(label);
  };

  return (
    <>
      <div className="flex flex-col gap-5">
        <FormInput
          label={globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.EMAIL_INPUT_LABEL}
          placeholder={
            globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.EMAIL_INPUT_PLACEHOLDER
          }
        />
        <FormInput
          label={globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.NAME_INPUT_LABEL}
          placeholder={
            globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.NAME_INPUT_PLACEHOLDER
          }
        />
        <FormInput
          label={
            globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.CARD_NUM_INPUT_LABEL
          }
          placeholder={
            globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM
              ?.CARD_NUM_INPUT_PLACEHOLDER
          }
        />

        <div className="flex flex-wrap justify-between gap-1 md:flex-nowrap md:gap-3">
          <div className="flex flex-col gap-2">
            <Label className="text-xs md:text-sm">Month</Label>
            <div className="w-56">
              <Dropdown
                options={monthOptions}
                selectedValue={month}
                onChange={(label) => handleMonthUpdate(label)}
                menuWidth="!w-56"
                placeholder="Select Month"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-xs md:text-sm">Year</Label>
            <div className="w-56">
              <Dropdown
                options={yearOptions}
                selectedValue={year}
                onChange={(label) => handleYearUpdate(label)}
                menuWidth="!w-56"
                placeholder="Select Year"
              />
            </div>
          </div>
          <div className="w-56">
            <FormInput
              label="CVV"
              placeholder="CVV"
              inputClassName="!h-11 !border-secondary-solid-hover"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <input
            checked={saveCard}
            id="checkbox"
            type="checkbox"
            onChange={() => setSaveCard(!saveCard)}
            className="focus:primary-solid h-4 w-4 rounded bg-bg-default accent-primary-solid"
          />
          <label
            htmlFor="checkbox"
            className="text-sm font-medium text-fg-text-contrast md:text-base"
          >
            {globalData?.PAYMENT_PAGE?.DEBIT_CARD_FORM?.CHECKBOX_TEXT}
          </label>
        </div>
      </div>
    </>
  );
};
