export const WelcomeScreen = () => {

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          <h1 className="text-4xl font-semibold text-fg-text-contrast">
            Welcome to the My Attorni Admin Panel!
          </h1>
          <p className="text-fg-text">
            Let's get started by selecting a country.
          </p>
        </div>
      </div>
    </>
  );
};
