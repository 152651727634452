import { useLocation } from "react-router-dom";
import { CalculateNavbar } from "../../components/layout";
import { CalculationForm } from "../../components/sections";
import { useMemo } from "react";

export const Calculate = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type: "courtFee" | "suitValuation" = (query.get("type") ||
    "courtFee") as "courtFee" | "suitValuation";
  const pageTitle = useMemo(() => {
    switch (type) {
      case "courtFee":
        return "Calculate Court Fee";
      case "suitValuation":
        return "Calculate Suit Valuation";
      default:
        return "";
    }
  }, [type]);

  const pageType = useMemo(() => type, [type]);

  return (
    <>
      <CalculateNavbar pageTitle={pageTitle} />
      <section className="mx-auto flex h-full max-w-7xl px-4 py-12 lg:px-0">
        <CalculationForm pageType={pageType} />
        <div className=""></div>
      </section>
    </>
  );
};
