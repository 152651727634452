import * as React from "react";
import cn from "classnames";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "h-10 md:h-11 w-full rounded-md border border-secondary-solid bg-transparent px-3.5 text-sm font-medium text-fg-text-contrast outline-none placeholder:text-secondary-solid focus:border-fg-border disabled:cursor-not-allowed disabled:opacity-50 md:text-base",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";
